import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl'
import { Heading, Link as RebassLink, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionWithObjects } from '../components/section'
import Container from '../components/container'
import getLocaleFromPathname from '../utils/getLocaleFromPathname'

const NotFoundPage = ({ location }) => {
  const intl = useIntl()
  const locale = getLocaleFromPathname(location.pathname)

  return (
    <Layout locale={locale}>
      <SEO
        title={intl.formatMessage({
          id: 'notFound.pageNotFound',
          defaultMessage: 'Stránka nenalezena',
        })}
      />

      <SectionWithObjects pt={[3, 5, 6]}>
        <Container>
          <Heading as="h1" variant="sectionTitle">
            <FormattedMessage
              id="notFound.pageNotFound"
              defaultMessage="Stránka nenalezena"
            />
          </Heading>

          <Text as="p" color="white" mb={4}>
            <FormattedMessage
              id="notFound.notFoundText"
              defaultMessage="Na této adrese bohužel nic nemáme. Zkuste to z hlavní strany."
            />
          </Text>

          <Link to="/">
            <RebassLink
              as="span"
              variant="primary"
              sx={{ display: 'inline-block' }}
            >
              <FormattedMessage
                id="notFound.gotoHomepage"
                defaultMessage="Jděte na hlavní stranu"
              />
            </RebassLink>
          </Link>
        </Container>
      </SectionWithObjects>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default NotFoundPage
